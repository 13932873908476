import React from 'react'
import "./src/styles/global.css"
import 'swiper/css';
import 'animate.css';
import 'video-react/dist/video-react.css';
import {ScreenProvider} from "./src/context/getScreen"
import 'react-lazy-load-image-component/src/effects/blur.css';

export const wrapRootElement = ({ element }) => (
    <ScreenProvider>{element}</ScreenProvider>
  )